
import { defineComponent, computed } from 'vue'
import { useDailyReport } from '@/composable/useDailyReport'
import QTable from 'quasar/src/components/table/QTable.js';

export default defineComponent({
  name: 'DailyReportSummaryTable',

  setup() {
    const { isPrintMode, data } = useDailyReport()

    // const colStyle = 'white-space: pre-wrap'
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'punctuality',
        label: 'Punktlighet SE RT +3',
        field: 'punctuality',
        style: 'width: 100%;',
      },

      {
        align: 'left',
        name: 'deviatingCrew',
        label: 'Antal avvikande bemanning',
        field: 'deviatingCrew',
        style: '',
      },
      {
        align: 'left',
        name: 'withdrawn',
        label: 'Inställda tåg (Inkl. delinställda)',
        field: 'withdrawn',
        style: '',
      },
    ]

    const rows = computed(() => {
      return [
        {
          punctuality: data.value.summary.rows
            .find((x) => x.label === 'Punktlighet')
            ?.data.find((t) => t.tag === 'Total')?.percentage,
          deviatingCrew: data.value.summary.rows
            .find((x) => x.label === 'Bemanningsavvikelser')
            ?.data.find((t) => t.tag === 'Total')?.count,
          withdrawn: data.value.summary.rows
            .find((x) => x.label === 'Fordonsavvikelser')
            ?.data.find((t) => t.tag === 'Total')?.count,
        },
      ]
    })

    console.log('PROCENT', rows)

    return { rows, columns, isPrintMode }
  },
})
