import { render } from "./DailyReportSummaryTable.vue?vue&type=template&id=b2f966a8"
import script from "./DailyReportSummaryTable.vue?vue&type=script&lang=ts"
export * from "./DailyReportSummaryTable.vue?vue&type=script&lang=ts"

import "./DailyReportSummaryTable.vue?vue&type=style&index=0&id=b2f966a8&lang=scss"
const cssModules = script.__cssModules = {}
import style1 from "./DailyReportSummaryTable.vue?vue&type=style&index=1&id=b2f966a8&lang=scss&module=true"
cssModules["$style"] = style1
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTh,QTr,QTd});
