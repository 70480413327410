<template>
  <div class="q-pa-md">
    <q-table
      hide-bottom
      dense
      title="Innevarande månad"
      square
      :rows-per-page-options="[0]"
      :rows="rows"
      :columns="columns"
      class="dailyReportTrafficTable"
      separator="cell"
      :bordered="isPrintMode"
      :flat="isPrintMode"
    >
      <template v-slot:header-cell="props">
        <q-th :props="props">
          <div :style="props.col.style">
            {{ props.col.label }}
          </div>
        </q-th>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="punctuality" :props="props" style="white-space: pre-wrap">
            {{ props.row?.punctuality }}
          </q-td>
          <q-td key="deviatingCrew" :props="props">
            {{ props.row?.deviatingCrew }}
          </q-td>
          <q-td key="withdrawn" :props="props">
            {{ props.row?.withdrawn }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useDailyReport } from '@/composable/useDailyReport'
import { QTable } from 'quasar'

export default defineComponent({
  name: 'DailyReportSummaryTable',

  setup() {
    const { isPrintMode, data } = useDailyReport()

    // const colStyle = 'white-space: pre-wrap'
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'punctuality',
        label: 'Punktlighet SE RT +3',
        field: 'punctuality',
        style: 'width: 100%;',
      },

      {
        align: 'left',
        name: 'deviatingCrew',
        label: 'Antal avvikande bemanning',
        field: 'deviatingCrew',
        style: '',
      },
      {
        align: 'left',
        name: 'withdrawn',
        label: 'Inställda tåg (Inkl. delinställda)',
        field: 'withdrawn',
        style: '',
      },
    ]

    const rows = computed(() => {
      return [
        {
          punctuality: data.value.summary.rows
            .find((x) => x.label === 'Punktlighet')
            ?.data.find((t) => t.tag === 'Total')?.percentage,
          deviatingCrew: data.value.summary.rows
            .find((x) => x.label === 'Bemanningsavvikelser')
            ?.data.find((t) => t.tag === 'Total')?.count,
          withdrawn: data.value.summary.rows
            .find((x) => x.label === 'Fordonsavvikelser')
            ?.data.find((t) => t.tag === 'Total')?.count,
        },
      ]
    })

    console.log('PROCENT', rows)

    return { rows, columns, isPrintMode }
  },
})
</script>

<style lang="scss">
.dailyReportTrafficTable {
  .q-table__top {
    background-color: #00a540;
    color: white;
  }

  thead tr:first-child th {
    background-color: #00a540;
    color: white;
  }
}
</style>

<style lang="scss" module>
.totalRow {
  background-color: rgba(#00a540, 0.1);
  font-weight: 600;
}
</style>
