<template>
  <q-page class="dailyReportPage">
    <DailyReportExternalToolBar v-if="!isPrintMode" />

    <transition
      enter-active-class="animated animate__fadeIn"
      leave-active-class="animated animate__fadeOut"
      mode="out-in"
      :duration="140"
    >
      <q-scroll-area
        v-if="!loading"
        :visible="!isPrintMode"
        :style="{
          height: isPrintMode ? '0px' : 'calc(100vh - 183px)',
          minWidth: '100%',
        }"
      >
        <teleport to="#print" :disabled="!isPrintMode">
          <h4 class="q-pl-md" style="margin-bottom: 0" v-if="isPrintMode">
            Dygnsrapport {{ isDailyReportInternal ? 'intern' : 'extern' }}
          </h4>
          <h6 class="q-pl-md" style="margin-top: 0" v-if="isPrintMode">
            {{ date }}
          </h6>
          <div class="row" :style="{ display: 'block' }">
            <div class="col-12" v-if="showTrafficTable">
              <DailyReportExternalTrafficTable />
            </div>
            <div class="col-12" v-if="showMonthlySummary">
              <DailyReportSummaryTable />
            </div>
            <div class="col-12">
              <template v-if="isDailyReportInternal">
                <DailyReportVehicleStatusTable
                  :summary="withdrawalDashboardData.summary"
                />
              </template>
              <template v-else>
                <DailyReportExternalVehicleStatusTable
                  :summary="withdrawalDashboardData.summary"
                  v-if="can('report.dailyReportExternal.section.vehicleStatus')"
                />
              </template>
            </div>
          </div>
          <DailyReportEventTable
            v-if="isDailyReportInternal ? !isPrintMode : false"
          />
          <DailyReportExternalDeviationTable
            v-for="(item, $i) in filteredDeviations"
            :key="$i"
            :title="item.name"
            :data="item.deviations"
          />
        </teleport>
      </q-scroll-area>
      <div
        v-else
        style="height: calc(100vh - 183px)"
        class="full-width items-center justify-center flex"
      >
        <q-spinner color="secondary" size="10em" :thickness="1" />
      </div>
    </transition>
  </q-page>
</template>

<script lang="ts">
import {
  InjectionKey,
  computed,
  defineComponent,
  ref,
  provide,
  Ref,
  watch,
} from 'vue'
import DailyReportExternalToolBar from '@/components/daily-report-external-preview/DailyReportExternalToolBar.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import DailyReportExternalTrafficTable from '@/components/daily-report-external-preview/DailyReportExternalTrafficTable.vue'
import DailyReportExternalVehicleStatusTable from '@/components/daily-report-external-preview/DailyReportExternalVehicleStatusTable.vue'
import DailyReportExternalDeviationTable from '@/components/daily-report-external-preview/DailyReportExternalDeviationTable.vue'
import DailyReportSummaryTable from '@/components/daily-report/DailyReportSummaryTable.vue'
import { useVehicle } from '@/composable/useVehicle'
import { useProfile } from '@/composable/useProfile'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { DailyReportDeviation } from '@/types/daily-report-deviation'
import { useUserSettings } from '@/composable/useUserSettings'
import { useRoute } from 'vue-router'
import DailyReportVehicleStatusTable from '@/components/daily-report/DailyReportVehicleStatusTable.vue'
import DailyReportEventTable from '@/components/daily-report/DailyReportEventTable.vue'
import { format, isBefore, startOfDay } from 'date-fns'

export type DailyReportExternalTrafficData = Ref<{
  columns_top: {
    label: string
    visible: boolean | null
    colspan: number
  }[]

  columns_bottom: {
    label_custom: string
    label: string
    style: { [key: string]: string }
    visible: boolean
    tag: string
  }[]
}>
export const dailyReportExternalTrafficDataKey =
  Symbol() as InjectionKey<DailyReportExternalTrafficData>

export default defineComponent({
  name: 'DailyReportExternal',

  components: {
    DailyReportExternalToolBar,
    DailyReportExternalTrafficTable,
    DailyReportExternalVehicleStatusTable,
    DailyReportExternalDeviationTable,
    DailyReportVehicleStatusTable,
    DailyReportEventTable,
    DailyReportSummaryTable,
  },

  setup() {
    const { can, currentProject } = useProfile()
    const {
      loading: dailyReportLoadingState,
      data,
      date,
      isPrintMode,
      selectedFilterEventType,
      showMonthlySummary,
    } = useDailyReport()
    const { loading: loadingStateVehicle, fetchAll: fetchAllVehicle } =
      useVehicle()
    const {
      loading: withdrawalDashboardLoading,
      data: withdrawalDashboardData,
    } = useWithdrawalDashboard()
    const route = useRoute()
    const isDailyReportInternal = route.name === 'daily-report-preview'
    const showTrafficTable = computed(() => {
      return true
    })

    fetchAllVehicle()

    const loading = computed(() => {
      return (
        dailyReportLoadingState.value.trafficV2.getAll ||
        dailyReportLoadingState.value.traffic.getAll ||
        dailyReportLoadingState.value.vehicleStatus.getAll ||
        dailyReportLoadingState.value.deviation.getAll ||
        dailyReportLoadingState.value.event.getAll ||
        loadingStateVehicle.value.getAll ||
        withdrawalDashboardLoading.value.fetchSummary
      )
    })

    const trackHasComment = (trackName: string) => {
      return data.value.event.some(
        (x) =>
          x.luppTracks.some((y) => y.description === trackName) && x.description
      )
    }

    const filterDeviation = (deviation: DailyReportDeviation) => {
      return Boolean(
        !deviation.track ||
          trackHasComment(deviation.track) ||
          selectedFilterEventType.value.includes(deviation.type)
      )
    }

    const filterDeviation2 = (deviation: DailyReportDeviation) => {
      return Boolean(
        !deviation.track ||
          selectedFilterEventType.value.includes(deviation.type)
      )
    }

    const { settings } = useUserSettings()

    const setColumns = () => {
      const projectName: string = currentProject.value?.name as string
      const projectFilter =
        settings.value[projectName].dailyReportExternalV2.tracks || []

      const columns_top = [
        ...new Set(data.value.trafficV2.headers.map((x) => x.tag)),
      ].map((tag, i, arr) => ({
        label: tag,
        visible: true,
        colspan:
          data.value.trafficV2.headers.filter((x) => x.tag === tag).length +
          (i !== arr.length - 1 ? 1 : 0),
      }))

      const columns_bottom = data.value.trafficV2.headers
        .map(({ label, tag }, i) => {
          const hasFilterSaved = projectFilter.find((x) => x.label === label)
          const col = {
            label_custom:
              i === 2
                ? label.replace('pendeln', '\npendeln')
                : label.replace(' ', '\n'),
            label,
            tag,
            visible: hasFilterSaved ? hasFilterSaved.visible : true,
            style:
              currentProject.value?.name === 'krosatag'
                ? {
                    width: `160px`,
                    maxWidth: '160px',
                  }
                : {
                    width: `110px`,
                    maxWidth: '110px',
                  },
          }
          if (
            currentProject.value?.name === 'krosatag'
              ? i === 0
              : i === 4 || i === 0
          ) {
            return [
              {
                tag,
                label_custom: '',
                label: '',
                style: { width: 'auto' },
                visible: true,
              },
              col,
            ]
          }
          return col
        })
        .flat()

      return {
        columns_bottom,
        columns_top,
      }
    }

    const columns = ref(setColumns())

    watch(
      () => data.value,
      () => {
        columns.value = setColumns()
      },
      {
        deep: true,
      }
    )

    const filteredDeviations = computed(() => {
      return data.value.deviation
        .filter((deviation) => {
          return (
            deviation.deviations.filter(filterDeviation).length ||
            trackHasComment(deviation.name)
          )
        })
        .map((x) => ({
          ...x,
          track_short: x.deviations[0]?.track_short || '-',
          deviations: x.deviations.filter(filterDeviation2),
        }))
        .filter((x) => {
          if (x.track_short === '-') return true
          return columns.value.columns_bottom
            .filter((x) => x.visible)
            .map((x) => x.label)
            .includes(x.track_short)
        })
    })

    provide(dailyReportExternalTrafficDataKey, columns)

    return {
      can,
      date,
      data,
      loading,
      isPrintMode,
      withdrawalDashboardData,
      filteredDeviations,
      isDailyReportInternal,
      showTrafficTable,
      showMonthlySummary,
    }
  },
})
</script>

<style lang="scss">
.dailyReportPrintMode {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 100%;
  width: 100%;
  z-index: 9999;
  overflow-y: scroll;
  overflow-x: scroll;
}

.printMode .dailyReportPage {
  min-height: 0 !important;
}

.printMode .q-scrollarea__thumb {
  opacity: 0 !important;
}
</style>
